import dynamic from "next/dynamic"

export const blockMapping = {
  /**
   * Core
   */
  "core/button": dynamic(() => import("@/blocks/core/button/button")),
  "core/buttons": dynamic(() => import("@/blocks/core/buttons/buttons")),
  "core/quote": dynamic(() => import("@/blocks/core/quote/quote")),
  "core/group": dynamic(() => import("@/blocks/core/group/group")),
  "core/heading": dynamic(() => import("@/blocks/core/heading/heading")),
  "core/image": dynamic(() => import("@/blocks/core/image/image")),
  "core/list": dynamic(() => import("@/blocks/core/list/list")),
  "core/paragraph": dynamic(() => import("@/blocks/core/paragraph/paragraph")),
  "core/table": dynamic(() => import("@/blocks/core/table/table")),

  /**
   * Core Custom
   */
  "core/login-form": dynamic(() =>
    import("@/blocks/core/login-form/login-form")
  ),
  "core/password-form": dynamic(() =>
    import("@/blocks/core/password-form/password-form")
  ),

  /**
   * Archive
   */
  "theme/archive": dynamic(() => import("@/blocks/theme/archive/archive")),

  /**
   * Theme
   */
  "theme/accordion": dynamic(() =>
    import("@/blocks/theme/accordion/accordion")
  ),
  "theme/accordion-item": dynamic(() =>
    import("@/blocks/theme/accordion/item")
  ),
  "theme/cookie-declaration": dynamic(() =>
    import("@/blocks/theme/cookie-declaration/cookie-declaration")
  ),
  "theme/faq": dynamic(() => import("@/blocks/theme/faq/faq")),
  "theme/featured-article": dynamic(() =>
    import("@/blocks/theme/featured-article/featured-article")
  ),
  "theme/google-map": dynamic(() =>
    import("@/blocks/theme/google-map/google-map")
  ),
  "theme/hero": dynamic(() => import("@/blocks/theme/hero/hero")),
  "theme/columns-image-cards": dynamic(() =>
    import("@/blocks/theme/columns-image-cards/columns-image-cards")
  ),
  "theme/image-card": dynamic(() =>
    import("@/blocks/theme/columns-image-cards/image-card")
  ),
  "theme/columns-text-cards": dynamic(() =>
    import("@/blocks/theme/columns-text-cards/columns-text-cards")
  ),
  "theme/text-card": dynamic(() =>
    import("@/blocks/theme/columns-text-cards/text-card")
  ),
  "theme/article-teasers": dynamic(() =>
    import("@/blocks/theme/article-teasers/article-teasers")
  ),
  "theme/image-slider": dynamic(() =>
    import("@/blocks/theme/image-slider/image-slider")
  ),
  "theme/youtube": dynamic(() => import("@/blocks/theme/youtube/youtube")),
  "theme/post-slider": dynamic(() =>
    import("@/blocks/theme/post-slider/post-slider")
  ),
  "theme/lightbox": dynamic(() => import("@/blocks/theme/lightbox/lightbox")),
  "theme/gravity-form": dynamic(() =>
    import("@/blocks/theme/gravity-form/gravity-form")
  ),
  "theme/text-block": dynamic(() =>
    import("@/blocks/theme/text-block/text-block")
  ),
  "theme/text-block-heading": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-heading")
  ),
  "theme/text-block-body": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-body")
  ),
  "theme/image-and-text": dynamic(() =>
    import("@/blocks/theme/image-and-text/image-and-text")
  ),
  "theme/link": dynamic(() => import("@/blocks/theme/link/link")),
  "theme/links": dynamic(() => import("@/blocks/theme/links/links")),
  "theme/files": dynamic(() => import("@/blocks/theme/files/files")),
  "theme/file": dynamic(() => import("@/blocks/theme/files/file")),
  "theme/organization": dynamic(() =>
    import("@/blocks/theme/organization/organization")
  ),
  "theme/persons": dynamic(() => import("@/blocks/theme/persons/persons")),
  /**
   * Article
   */
  "theme/article-hero": dynamic(() =>
    import("@/blocks/theme/article-hero/article-hero")
  ),
  "theme/article-tag": dynamic(() =>
    import("@/blocks/theme/article-hero/article-tag")
  ),
  "theme/article-terms": dynamic(() =>
    import("@/blocks/theme/article-hero/article-terms")
  ),
  "theme/article-meta": dynamic(() =>
    import("@/blocks/theme/article-hero/article-meta")
  ),
  "theme/article-sidebars": dynamic(() =>
    import("@/blocks/theme/article-sidebars/article-sidebars")
  ),
  "theme/article-cta": dynamic(() =>
    import("@/blocks/theme/article-cta/article-cta")
  ),

  // Search page
  "theme/search": dynamic(() => import("@/blocks/theme/search/search"))
}
