import { useEffect, useState } from "react"
import { useHasHero, useIsStart } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAdminBar
} from "@/lib/contexts/appContext"
import Main from "@/components/wrappers/main"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Blocks from "@/components/Blocks"
import styles from "./page.module.css"
import classNames from "classnames/bind"

export default function Page() {
  const pathData = usePathData()
  const object = usePropsObject()
  const isStart = useIsStart()
  const heroFormat = useHasHero()
  const cx = classNames.bind(styles)
  const adminBar = useAdminBar()
  const { isArchive } = object
  const [classes, setClasses] = useState({
    main: true
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "no-hero": !heroFormat && !isArchive,
      "is-archive": isArchive,
      "has-admin-bar": adminBar,
      "is-white-page": object?.pageColor === "white"
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <Main className={cx(classes)}>
      {!isStart && !heroFormat && !isArchive && (
        <Breadcrumbs className="mt-4 mb-5 lg:ml-0" />
      )}
      <Blocks blocks={pathData.content} />
    </Main>
  )
}
